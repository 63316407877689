import React from 'react'
import { useStyles } from './style'
import Member from '../Member'

function Matrimony({ partnerId, memberComponent: MemberComponent }) {
  const classes = useStyles()
  return (
    <>
      <div className={classes.root}>
        {MemberComponent ? (
          <MemberComponent memberId={partnerId} isMatrimony={true} />
        ) : (
          <Member memberId={partnerId} />
        )}
      </div>

      {/*children && (
        <div className={classes.children}>
          {children.map((item, key) => (
            <MemberGroup
              group={item}
              key={key}
              memberComponent={MemberComponent}
            />
          ))}
        </div>
      )*/}
    </>
  )
}

export default Matrimony
