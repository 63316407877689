import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import { messages } from '../utils/validation'
import { updateMember, removeMember } from '../store/actions/family'
import ApproveRemovingDialog from '../dialogs/ApproveRemovingDialog'

function EditMemberForm({
  member,
  id,
  afterSubmit,
  allowRemoving,
  afterRemove
}) {
  const [showApproveRemoving, setShowApproveRemoving] = useState(false)
  const dispatch = useDispatch()
  const { register, handleSubmit, errors, control } = useForm()
  const onSubmit = async data => {
    const result = await dispatch(updateMember(id, data))
    if (result) {
      afterSubmit()
    }
  }

  const doRemoveMember = async () => {
    const result = await dispatch(removeMember(id))
    if (result) {
      afterRemove()
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item sm={4}>
            <TextField
              label="Name"
              variant="outlined"
              defaultValue={member.name}
              fullWidth
              inputRef={register({ required: messages.required })}
              name="name"
              error={!!errors.name}
              helperText={errors?.name?.message}
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              label="Last name"
              variant="outlined"
              defaultValue={member.lastName}
              fullWidth
              inputRef={register}
              name="lastName"
              error={!!errors.lastName}
              helperText={errors?.lastName?.message}
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              label="Maiden name"
              variant="outlined"
              defaultValue={member.maidenName}
              fullWidth
              inputRef={register}
              name="maidenName"
              error={!!errors.maidenName}
              helperText={errors?.maidenName?.message}
            />
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: 0 }}>
            <Divider style={{ marginBottom: 8 }} />
            <Typography variant="subtitle1">Residence location</Typography>
          </Grid>
          <Grid item sm={4}>
            <TextField
              label="Country"
              variant="outlined"
              defaultValue={member.location?.country || ''}
              fullWidth
              inputRef={register}
              name="location.country"
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              label="Region"
              variant="outlined"
              defaultValue={member.location?.region || ''}
              fullWidth
              inputRef={register}
              name="location.region"
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              label="Town"
              variant="outlined"
              defaultValue={member.location?.town || ''}
              fullWidth
              inputRef={register}
              name="location.town"
            />
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: 0 }}>
            <Divider style={{ marginBottom: 8 }} />
            <Typography variant="subtitle1">Birth</Typography>
          </Grid>
          <Grid item sm={4}>
            <Controller
              control={control}
              name="birthDate"
              defaultValue={member.birthDate || null}
              render={({ onChange, value }) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    id="date-picker-inline"
                    label="Birth date"
                    value={value}
                    onChange={onChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </MuiPickersUtilsProvider>
              )}
            />
          </Grid>
          <Grid item sm={8}></Grid>
          <Grid item sm={4}>
            <TextField
              label="Country"
              variant="outlined"
              defaultValue={member.birthLocation?.country || ''}
              fullWidth
              inputRef={register}
              name="birthLocation.country"
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              label="Region"
              variant="outlined"
              defaultValue={member.birthLocation?.region || ''}
              fullWidth
              inputRef={register}
              name="birthLocation.region"
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              label="Town"
              variant="outlined"
              defaultValue={member.birthLocation?.town || ''}
              fullWidth
              inputRef={register}
              name="birthLocation.town"
            />
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: 0 }}>
            <Divider style={{ marginBottom: 8 }} />
            <Typography variant="subtitle1">Death</Typography>
          </Grid>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={member.isDead}
                  inputRef={register}
                  name="isDead"
                />
              }
              label="Is dead"
            />
          </Grid>
          <Grid item sm={4}>
            <Controller
              control={control}
              name="deathDate"
              defaultValue={member.deathDate || null}
              render={({ onChange, value }) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    id="date-picker-inline"
                    label="Death date"
                    value={value}
                    onChange={onChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </MuiPickersUtilsProvider>
              )}
            />
          </Grid>
          <Grid item sm={4} />
          <Grid item sm={4}>
            <TextField
              label="Country"
              variant="outlined"
              defaultValue={member.deathLocation?.country || ''}
              fullWidth
              inputRef={register}
              name="deathLocation.country"
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              label="Region"
              variant="outlined"
              defaultValue={member.deathLocation?.region || ''}
              fullWidth
              inputRef={register}
              name="deathLocation.region"
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              label="Town"
              variant="outlined"
              defaultValue={member.deathLocation?.town || ''}
              fullWidth
              inputRef={register}
              name="deathLocation.town"
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              size="large"
            >
              Save
            </Button>
            {allowRemoving && (
              <Button
                color="secondary"
                variant="contained"
                size="large"
                style={{ marginLeft: 24 }}
                onClick={() => setShowApproveRemoving(true)}
              >
                Remove
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
      <ApproveRemovingDialog
        open={showApproveRemoving}
        name={member.name}
        onClose={() => setShowApproveRemoving(false)}
        doRemove={doRemoveMember}
      />
    </>
  )
}

export default EditMemberForm
