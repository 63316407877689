import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchFamilyTree, fetchExtendedData } from '../../store/actions/family'
import {
  calculateRelationsGeometry,
  saveTreeGeometry
} from '../../store/actions/ui'

function useFetchTree(alias) {
  const fetchNeeded = useSelector(state => state.flags.fetchNeeded)
  const dispatch = useDispatch()

  useEffect(() => {
    if (fetchNeeded) {
      dispatch(fetchFamilyTree(alias))
    }
  }, [dispatch, alias, fetchNeeded])
}

function useCalculateTreeGeometry(treeRef) {
  const membersRendered = useSelector(state => state.flags.membersRendered)
  const dispatch = useDispatch()

  useEffect(() => {
    if (membersRendered) {
      const geo = {}
      const rect = treeRef.current.getBoundingClientRect()
      ;['left', 'top', 'right', 'bottom'].forEach(key => {
        geo[key] = Math.round(rect[key])
      })
      dispatch(saveTreeGeometry(geo))
    }
  }, [treeRef, membersRendered, dispatch])
}

function useCalculateRelationsGeometry() {
  const membersGeoCalculated = useSelector(
    state => state.flags.membersGeometryCalculated
  )
  const dispatch = useDispatch()
  useEffect(() => {
    if (membersGeoCalculated) {
      dispatch(calculateRelationsGeometry())
    }
  }, [membersGeoCalculated, dispatch])
}

function useFetchExtendedData(alias) {
  const relationsPainted = useSelector(state => state.flags.relationsPainted)
  const dispatch = useDispatch()

  useEffect(() => {
    if (relationsPainted) {
      dispatch(fetchExtendedData(alias))
    }
  }, [dispatch, alias, relationsPainted])
}

export {
  useFetchTree,
  useCalculateTreeGeometry,
  useCalculateRelationsGeometry,
  useFetchExtendedData
}
