import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import { useStyles } from './style'
import {
  useMemberRendered,
  useCalculateMemberGeometry,
  useGetParents,
  useAllowRemoving
} from './hooks'
import AddMemberDialog from '../../dialogs/AddMember'
import EditMemberDialog from '../../dialogs/EditMember'

function Member({ memberId, isMatrimony }) {
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const member = useSelector(state => state.family.members.get(memberId))
  const classes = useStyles()
  const cardRef = useRef(null)

  useMemberRendered()

  useCalculateMemberGeometry(memberId, cardRef)

  const parents = useGetParents(memberId)
  const allowRemoving = useAllowRemoving(memberId)

  return (
    <>
      <Card className={classes.root} ref={cardRef}>
        <div className={classes.content}>
          {member.thumbnail && (
            <CardMedia
              image={`${process.env.REACT_APP_UPLOADS_BASE_URL}${member.thumbnail}`}
              className={classes.image}
            />
          )}
          <div className={classes.details}>
            <Typography variant="subtitle1">{`${member.name}${
              member.lastName ? ` ${member.lastName}` : ''
            }${
              member.maidenName ? ` (${member.maidenName})` : ''
            }`}</Typography>
          </div>

          <div style={{ position: 'absolute', right: 0, bottom: 0 }}>
            {!isMatrimony && (
              <IconButton
                aria-label="add"
                color="primary"
                onClick={() => setOpenAddDialog(true)}
              >
                <AddIcon />
              </IconButton>
            )}
            <IconButton
              aria-label="add"
              color="primary"
              onClick={() => setOpenEditDialog(true)}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </div>
        </div>
      </Card>
      {!isMatrimony && (
        <AddMemberDialog
          open={openAddDialog}
          onClose={() => setOpenAddDialog(false)}
          memberId={memberId}
          noParentAdding={parents.length > 0}
        />
      )}
      <EditMemberDialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        member={member}
        isMatrimonyPartner={isMatrimony}
        id={memberId}
        parents={parents}
        allowRemoving={allowRemoving}
      />
    </>
  )
}

export default Member
