import { makeStyles } from '@material-ui/core/styles'
import { MEMBER_DISTANCE_X, MEMBER_DISTANCE_Y } from '../../const'

const styles = {
  root: {
    display: 'inline-block',
    paddingLeft: MEMBER_DISTANCE_X
  },
  children: {
    paddingTop: MEMBER_DISTANCE_Y,
    whiteSpace: 'nowrap',
    // display: 'flex',
    pointerEvents: 'none'
  }
}

export const useStyles = makeStyles(styles)
