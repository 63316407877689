import { types } from '../actions/family'
import { family as initialState } from '../initialState'

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ID:
      return { ...state, id: action.value }
    case types.SET_MEMBERS:
      return { ...state, members: action.value }
    case types.SET_MATRIMONIES:
      return { ...state, matrimonies: action.value }
    case types.SET_TREE:
      return { ...state, tree: action.value }
    // case types.SET_ALIAS:
    //   return { ...state, alias: action.alias }
    default:
      return state
  }
}
