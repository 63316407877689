import { makeStyles } from '@material-ui/core/styles'

const styles = {
  root: {
    display: 'inline-block',
    position: 'relative',
    padding: '16px 32px',
    pointerEvents: 'none',
    minWidth: '100%'
  },
  tree: {
    textAlign: 'center',
    position: 'relative',
    pointerEvents: 'none'
  }
}

export const useStyles = makeStyles(styles)
