import { types } from '../actions/ui'
import { ui as initialState } from '../initialState'

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SHOW_GLOBAL_LOADER:
      return { ...state, showGlobalLoader: action.value }
    case types.INCREMENT_RENDERED_MEMBERS_COUNTER:
      return {
        ...state,
        renderedMembersCounter: state.renderedMembersCounter + 1
      }
    case types.SET_MEMBER_GEOMETRY:
      return {
        ...state,
        membersGeometry: {
          ...state.membersGeometry,
          [action.id]: action.value
        }
      }
    case types.SET_TREE_GEOMETRY:
      return { ...state, treeGeometry: action.value }
    case types.SET_RELATIONS_GEOMETRY:
      return { ...state, relationsGeometry: action.value }
    case types.SET_ZOOM_LEVEL:
      return { ...state, zoomLevel: action.value }
    default:
      return state
  }
}
