import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import MemberDetails from '../../dialogs/MemberDetails'
import { getShortLocationString } from '../../utils/str'
import { dateToShortString } from '../../utils/date'
import { useStyles } from './style'
import { useMemberRendered, useCalculateMemberGeometry } from './hooks'

function Member({ memberId }) {
  const [showDetails, setShowDetails] = useState(false)
  const member = useSelector(state => state.family.members.get(memberId))
  const classes = useStyles()
  const cardRef = useRef(null)

  useMemberRendered()

  useCalculateMemberGeometry(memberId, cardRef)

  const onMemberClick = e => {
    e.preventDefault()
    setShowDetails(true)
  }

  return (
    <>
      <Card
        className={classes.root}
        ref={cardRef}
        onClick={onMemberClick}
        style={{ cursor: 'pointer' }}
      >
        <div className={classes.content}>
          {member.thumbnail && (
            <CardMedia
              image={`${process.env.REACT_APP_UPLOADS_BASE_URL}${member.thumbnail}`}
              className={`${classes.image} ${
                member.isDead || member.deathDate ? classes.bw : ''
              }`}
            />
          )}
          <div className={classes.details}>
            <Typography variant="subtitle1">{`${member.name}${
              member.lastName ? ` ${member.lastName}` : ''
            }${
              member.maidenName ? ` (${member.maidenName})` : ''
            }`}</Typography>

            {(member.birthDate || member.deathDate) && (
              <Typography variant="body2">{`${
                member.birthDate ? dateToShortString(member.birthDate) : 'N/A'
              }${
                member.isDead || member.deathDate
                  ? ` - ${
                      member.deathDate
                        ? dateToShortString(member.deathDate)
                        : 'N/A'
                    }`
                  : ''
              }`}</Typography>
            )}

            {Object.values(member.location || []).some(val => !!val) && (
              <Typography variant="body2">
                {getShortLocationString(member.location)}
              </Typography>
            )}
          </div>
        </div>
      </Card>

      <MemberDetails
        open={showDetails}
        onClose={() => setShowDetails(false)}
        member={member}
      />
    </>
  )
}

export default Member
