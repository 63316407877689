import { batch } from 'react-redux'
import {
  getFamilyTree,
  rebuild as fetchRebuid,
  getExtendedData as fetchGetExtendedData
} from '../../api/family'
import { add as fetchUpload } from '../../api/upload'
import {
  update as fetchUpdateMember,
  add as fetchAddMember,
  remove as fetchRemoveMember
} from '../../api/member'
import { add as fetchAddMatrimony } from '../../api/matrimony'
import { setShowGlobalLoader } from './ui'
import {
  setTreeFetched,
  setMembersRendered,
  setTreeGeometryCalculated,
  setMembersGeometryCalculated,
  setRelationsGeometryCalculated,
  setRelationsPainted,
  setFetchNeeded,
  setExtendedDataFetched
} from './flags'
import { addError } from './app'

const types = {
  SET_ID: 'SET_ID',
  SET_TREE: 'SET_TREE',
  SET_MEMBERS: 'SET_MEMBERS',
  SET_MATRIMONIES: 'SET_MATRIMONIES'
  // SET_ALIAS: 'SET_ALIAS'
}

function setId(value) {
  return {
    type: types.SET_ID,
    value
  }
}

function setTree(value) {
  return {
    type: types.SET_TREE,
    value
  }
}

// function setAlias(value) {
//   return {
//     type: types.SET_ALIAS,
//     value
//   }
// }

function setMembers(value) {
  return {
    type: types.SET_MEMBERS,
    value:
      value instanceof Map
        ? value
        : new Map(
            value.map(m => {
              const { id, ...member } = m
              return [id, member]
            })
          )
  }
}

function setMatrimonies(value) {
  return {
    type: types.SET_MATRIMONIES,
    value:
      value instanceof Map
        ? value
        : new Map(
            value.map(m => {
              const { id, ...matrimony } = m
              return [id, matrimony]
            })
          )
  }
}

function fetchFamilyTree(alias) {
  return async dispatch => {
    try {
      dispatch(setFetchNeeded(false))
      dispatch(setShowGlobalLoader(true))
      const { id, tree, members, matrimonies } = await getFamilyTree(alias)
      batch(() => {
        dispatch(setId(id))
        dispatch(setTree(tree))
        dispatch(setMembers(members))
        dispatch(setMatrimonies(matrimonies))
      })
      dispatch(setShowGlobalLoader(false))
      dispatch(setTreeFetched(true))
    } catch (e) {
      dispatch(setShowGlobalLoader(false))
      dispatch(addError())
    }
  }
}

function fetchExtendedData(alias) {
  return async (dispatch, getState) => {
    try {
      const membersData = await fetchGetExtendedData(alias)

      const state = getState()
      const map = new Map()
      state.family.members.forEach((primaryData, id) => {
        map.set(id, { ...primaryData, ...(membersData[id] || {}) })
      })
      dispatch(setMembers(map))
      dispatch(setExtendedDataFetched(true))
    } catch (e) {
      dispatch(addError())
    }
  }
}

function updateMember(id, data, reloadTreeAfterUpdate = false) {
  return async (dispatch, getState) => {
    const result = await fetchUpdateMember(id, data)
    if (result) {
      if (reloadTreeAfterUpdate) {
        dispatch(reloadTree())
      } else {
        const state = getState()
        const members = new Map(state.family.members)
        const member = members.get(id)
        members.set(id, { ...member, ...data })
        dispatch(setMembers(members))
      }
      return true
    }
    return false
  }
}

function uploadImage(id, body) {
  return async (dispatch, getState) => {
    const result = await fetchUpload(body)
    if (result) {
      const thumbnail =
        result[0] && result[0].formats && result[0].formats.thumbnail
      const state = getState()
      const members = new Map(state.family.members)
      const member = members.get(id)
      members.set(id, { ...member, thumbnail: thumbnail.url })
      dispatch(setMembers(members))

      // reload static tree on server
      const familyId = state.family.id
      fetchRebuid(familyId)
      return true
    }
    return false
  }
}

function removeMember(id) {
  return async dispatch => {
    const result = await fetchRemoveMember(id)
    if (result) {
      dispatch(reloadTree())
      return true
    }
    return false
  }
}

function addParent(id, data) {
  return async (dispatch, getState) => {
    const familyId = getState().family.id
    const result = await fetchAddMember({
      ...data,
      children: [id],
      family: familyId
    })
    if (result) {
      dispatch(reloadTree())
    }
    return false
  }
}

function addChild(id, data) {
  return async (dispatch, getState) => {
    const familyId = getState().family.id
    const result = await fetchAddMember({
      ...data,
      parents: [id],
      family: familyId
    })
    if (result) {
      dispatch(reloadTree())
    }
    return false
  }
}

function addPartner(id, data) {
  return async (dispatch, getState) => {
    const familyId = getState().family.id
    const addedPartner = await fetchAddMember({
      ...data,
      family: familyId
    })
    if (addedPartner) {
      const addedMatrimony = await fetchAddMatrimony({
        members: [id, addedPartner.id]
      })
      if (addedMatrimony) {
        dispatch(reloadTree())
      }
    }
    return false
  }
}

function reloadTree() {
  return async dispatch => {
    batch(() => {
      dispatch(setFetchNeeded(true))
      dispatch(setTreeFetched(false))
      dispatch(setMembersRendered(false))
      dispatch(setTreeGeometryCalculated(false))
      dispatch(setMembersGeometryCalculated(false))
      dispatch(setRelationsGeometryCalculated(false))
      dispatch(setRelationsPainted(false))
    })
  }
}

export {
  types,
  fetchFamilyTree,
  updateMember,
  addParent,
  addChild,
  addPartner,
  removeMember,
  uploadImage,
  fetchExtendedData
}
