import { createSelector } from 'reselect'
import { countTreeMembers } from './family'

const renderedMembersCounterSelector = state => state.ui.renderedMembersCounter
const membersGeometrySelector = state => state.ui.membersGeometry

const areAllMembersRendered = createSelector(
  [renderedMembersCounterSelector, countTreeMembers],
  (renderedCounter, allCounter) => allCounter && !(allCounter > renderedCounter)
)

const areAllMembersGeometryCalculated = createSelector(
  [membersGeometrySelector, countTreeMembers],
  (membersGeometry, allCounter) => {
    return allCounter && !(allCounter > Object.keys(membersGeometry).length)
  }
)

export { areAllMembersRendered, areAllMembersGeometryCalculated }
