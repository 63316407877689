import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { getLocationString } from '../utils/str'
import { dateToShortString } from '../utils/date'

function MemberDetails({ open, onClose, member }) {
  const locationString = getLocationString(member.location)
  const birthLocationString = getLocationString(member.birthLocation)
  const deathLocationString = getLocationString(member.deathLocation)
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="member-details-dialog-title"
      open={open}
      fullWidth
    >
      <DialogTitle id="member-details-dialog-title">{member.name}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item>
            {member.image && (
              <img
                src={`${process.env.REACT_APP_UPLOADS_BASE_URL}${member.thumbnail}`}
                alt={member.name}
              />
            )}
          </Grid>
          <Grid item>
            {locationString && (
              <Typography variant="body1">
                Місце проживання: {locationString}
              </Typography>
            )}
            <Typography variant="body1">{`Дата народження: ${
              member.birthDate ? dateToShortString(member.birthDate) : 'N/A'
            }`}</Typography>
            {birthLocationString && (
              <Typography variant="body1">{`Місце народження: ${birthLocationString}`}</Typography>
            )}
            {(member.deathDate || member.isDead) && (
              <Typography variant="body1">
                Дата смерті:{' '}
                {member.deathDate ? dateToShortString(member.deathDate) : 'N/A'}
              </Typography>
            )}
            {deathLocationString && (
              <Typography variant="body1">
                Місце смерті: {deathLocationString}
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default MemberDetails
