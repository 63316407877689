import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { useStyles } from './style'
import {
  useFetchTree,
  useCalculateTreeGeometry,
  useCalculateRelationsGeometry,
  useFetchExtendedData
} from './hooks'
import MemberGroup from '../MemberGroup'
import RelationsCanvas from '../RelationsCanvas'

function Tree({ memberComponent }) {
  const tree = useSelector(state => state.family.tree)
  const treeFetched = useSelector(state => state.flags.treeFetched)
  const classes = useStyles()
  const treeRef = useRef(null)

  // For now we take tree identificator (alias) from env variables only
  const alias = process.env.REACT_APP_TREE_ALIAS

  useFetchTree(alias)

  useCalculateTreeGeometry(treeRef)

  useCalculateRelationsGeometry()

  useFetchExtendedData(alias)

  return (
    <div className={classes.root}>
      <RelationsCanvas />
      <div className={classes.tree} ref={treeRef}>
        {treeFetched &&
          (tree ? (
            <MemberGroup group={tree} memberComponent={memberComponent} />
          ) : (
            'Family tree is empty yet.'
          ))}
      </div>
    </div>
  )
}

export default Tree
