import { useState, useEffect, useCallback } from 'react'

function useGrabbing(containerRef) {
  // const container = (containerRef || {}).current
  const [initials, setInitials] = useState({
    scrollX: 0,
    scrollY: 0,
    mouseX: 0,
    mouseY: 0
  })
  const [isMouseDown, setIsMouseDown] = useState(false)

  const startGrabbing = useCallback((e, container) => {
    if (e.target === container) {
      e.preventDefault()
      e.stopPropagation()
      setInitials({
        scrollX: container.scrollLeft,
        scrollY: container.scrollTop,
        mouseX: e.clientX,
        mouseY: e.clientY
      })
      setIsMouseDown(true)

      container.style.cursor = 'grabbing'
      container.style.userSelect = 'none'
    }
  }, [])

  const doGrabbing = useCallback(
    (e, container) => {
      if (isMouseDown) {
        const movedX = e.clientX - initials.mouseX
        const movedY = e.clientY - initials.mouseY
        container.scrollTop = initials.scrollY - movedY
        container.scrollLeft = initials.scrollX - movedX
      }
    },
    [initials, isMouseDown]
  )

  const endGrabbing = useCallback((e, container) => {
    e.preventDefault()
    e.stopPropagation()
    setIsMouseDown(false)
    container.style.removeProperty('cursor')
    container.style.removeProperty('user-select')
  }, [])

  useEffect(() => {
    const container = containerRef.current

    const onMouseDown = e => startGrabbing(e, container)
    const onMouseMove = e => doGrabbing(e, container)
    const onMouseUp = e => endGrabbing(e, container)
    if (container) {
      container.addEventListener('mousedown', onMouseDown)
      container.addEventListener('mousemove', onMouseMove)
      container.addEventListener('mouseup', onMouseUp)
    }
    return () => {
      container.removeEventListener('mousedown', onMouseDown)
      container.removeEventListener('mousemove', onMouseMove)
      container.removeEventListener('mouseup', onMouseUp)
    }
  }, [containerRef, startGrabbing, doGrabbing, endGrabbing])
}

export { useGrabbing }
