import {
  areAllMembersRendered,
  areAllMembersGeometryCalculated
} from '../selectors/ui'
import {
  setMembersRendered,
  setTreeGeometryCalculated,
  setMembersGeometryCalculated,
  setRelationsGeometryCalculated
} from './flags'
import {
  MEMBER_DISTANCE_X,
  MEMBER_DISTANCE_Y,
  ZOOM_STEP,
  ZOOM_MIN,
  ZOOM_MAX
} from '../../const'

const types = {
  SET_SHOW_GLOBAL_LOADER: 'SET_SHOW_GLOBAL_LOADER',
  INCREMENT_RENDERED_MEMBERS_COUNTER: 'INCREMENT_RENDERED_MEMBERS_COUNTER',
  SET_TREE_GEOMETRY: 'SET_TREE_GEOMETRY',
  SET_MEMBER_GEOMETRY: 'SET_MEMBER_GEOMETRY',
  SET_RELATIONS_GEOMETRY: 'SET_RELATIONS_GEOMETRY',
  SET_ZOOM_LEVEL: 'SET_ZOOM_LEVEL'
}

function setShowGlobalLoader(value) {
  return {
    type: types.SET_SHOW_GLOBAL_LOADER,
    value
  }
}

function incrementRenderedMembersCounter() {
  return {
    type: types.INCREMENT_RENDERED_MEMBERS_COUNTER
  }
}

function setTreeGeometry(value) {
  return {
    type: types.SET_TREE_GEOMETRY,
    value
  }
}

function setMemberGeometry({ id, value }) {
  return {
    type: types.SET_MEMBER_GEOMETRY,
    id,
    value
  }
}

function setRelationsGeometry(value) {
  return {
    type: types.SET_RELATIONS_GEOMETRY,
    value
  }
}

function onMemberRendered() {
  return (dispatch, getState) => {
    dispatch(incrementRenderedMembersCounter())
    if (areAllMembersRendered(getState()) === true) {
      dispatch(setMembersRendered(true))
    }
  }
}

function saveTreeGeometry(value) {
  return dispatch => {
    dispatch(setTreeGeometry(value))
    dispatch(setTreeGeometryCalculated(true))
  }
}

function saveMemberGeometry(value) {
  return (dispatch, getState) => {
    dispatch(setMemberGeometry(value))
    if (areAllMembersGeometryCalculated(getState()) === true) {
      dispatch(setMembersGeometryCalculated(true))
    }
  }
}

function calculateRelationsGeometry() {
  return (dispatch, getState) => {
    const state = getState()
    const { tree } = state.family
    const membersGeo = state.ui.membersGeometry
    const geo = []

    const calculateMatrimonyRelationGeometry = (matrimony, startPoint) => {
      const partnerGeo = membersGeo[matrimony.partnerId]
      const relationGeo = [
        startPoint,
        {
          x: partnerGeo.left,
          y: (partnerGeo.bottom - partnerGeo.top) / 2 + partnerGeo.top
        }
      ]
      geo.push(relationGeo)

      // Matrimony children
      if (matrimony.children && matrimony.children.length > 0) {
        const childrenRelationPoint = {
          x: partnerGeo.left - MEMBER_DISTANCE_X / 2,
          y: (partnerGeo.bottom - partnerGeo.top) / 2 + partnerGeo.top
        }
        matrimony.children.forEach(c =>
          calculateChildRelationGeometry(c, childrenRelationPoint)
        )
      }
    }

    const calculateChildRelationGeometry = (child, startPoint) => {
      const childGeo = membersGeo[child.id]
      const endPoint = {
        x: (childGeo.right - childGeo.left) / 2 + childGeo.left,
        y: childGeo.top
      }
      const relationGeo = [startPoint]
      if (startPoint.x !== endPoint.x) {
        relationGeo.push({
          x: startPoint.x,
          y: endPoint.y - MEMBER_DISTANCE_Y / 2
        })
        relationGeo.push({
          x: endPoint.x,
          y: endPoint.y - MEMBER_DISTANCE_Y / 2
        })
      }
      relationGeo.push(endPoint)

      geo.push(relationGeo)

      // child group
      calculateGroupRelationsGeometry(child)
    }

    const calculateGroupRelationsGeometry = topMember => {
      const memberGeo = membersGeo[topMember.id]

      // Matrimonies
      if (topMember.matrimonies && topMember.matrimonies.length > 0) {
        const matrimoniesStartPoint = {
          x: memberGeo.right,
          y: (memberGeo.bottom - memberGeo.top) / 2 + memberGeo.top
        }
        topMember.matrimonies.forEach(m =>
          calculateMatrimonyRelationGeometry(m, matrimoniesStartPoint)
        )
      }

      // Children
      if (topMember.children && topMember.children.length > 0) {
        const childrenRelationPoint = {
          x: (memberGeo.right - memberGeo.left) / 2 + memberGeo.left,
          y: memberGeo.bottom
        }
        topMember.children.forEach(c =>
          calculateChildRelationGeometry(c, childrenRelationPoint)
        )
      }
    }

    calculateGroupRelationsGeometry(tree)

    dispatch(setRelationsGeometry(geo))
    dispatch(setRelationsGeometryCalculated(true))
  }
}

function setZoomLevel(value) {
  return {
    type: types.SET_ZOOM_LEVEL,
    value: Math.round(value * 100) / 100
  }
}

function incrementZoomLevel() {
  return (dispatch, getState) => {
    const { zoomLevel } = getState().ui
    if (zoomLevel < ZOOM_MAX) {
      dispatch(setZoomLevel(zoomLevel + ZOOM_STEP))
    }
  }
}

function decrementZoomLevel() {
  return (dispatch, getState) => {
    const { zoomLevel } = getState().ui
    if (zoomLevel > ZOOM_MIN) {
      dispatch(setZoomLevel(zoomLevel - ZOOM_STEP))
    }
  }
}

export {
  types,
  setShowGlobalLoader,
  onMemberRendered,
  saveTreeGeometry,
  saveMemberGeometry,
  calculateRelationsGeometry,
  incrementZoomLevel,
  decrementZoomLevel
}
