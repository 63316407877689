import React from 'react'
import { useStyles } from './style'
import Member from '../Member'
import Matrimony from '../Matrimony'

function MemberGroup({ group, memberComponent: MemberComponent }) {
  const memberId = group.id
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {MemberComponent ? (
        <MemberComponent memberId={memberId} />
      ) : (
        <Member memberId={memberId} />
      )}
      {group.matrimonies &&
        group.matrimonies.map(({ id, partnerId }, key) => (
          <Matrimony
            matrimonyId={id}
            partnerId={partnerId}
            key={key}
            memberComponent={MemberComponent}
          />
        ))}
      <div className={classes.children}>
        {group.children &&
          group.children.map((item, key) => (
            <MemberGroup
              group={item}
              key={key}
              memberComponent={MemberComponent}
            />
          ))}

        {group.matrimonies &&
          group.matrimonies
            .map(({ children }) => children)
            .flat()
            .map(
              (item, key) =>
                item && (
                  <MemberGroup
                    group={item}
                    key={key}
                    memberComponent={MemberComponent}
                  />
                )
            )}
      </div>
    </div>
  )
}

export default MemberGroup
