import React from 'react'
import { useSelector } from 'react-redux'

function ZoomContainer({ children }) {
  const zoomLevel = useSelector(state => state.ui.zoomLevel)

  return (
    <div
      style={{
        transform: `scale(${zoomLevel})`,
        transformOrigin: 'top center',
        pointerEvents: 'none'
      }}
    >
      {children}
    </div>
  )
}

export default ZoomContainer
