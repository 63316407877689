function normalizeDate(date) {
  return date instanceof Date
    ? date
    : new Date(typeof date === 'number' ? date * 1000 : date)
}

function dateToLocaleString(date) {
  if (!date) {
    return ''
  }
  const dateObj = normalizeDate(date)
  return dateObj.toLocaleDateString('uk-UA', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })
}

function dateToShortString(date) {
  if (!date) {
    return ''
  }
  const dateObj = normalizeDate(date)
  return dateObj.toLocaleDateString('uk-UA')
}

/* eslint-disable import/prefer-default-export */
export { dateToLocaleString, dateToShortString }
